import React from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';

interface SidebarInfoProps {
    onClose: () => void;
}

// Simple placeholder component that replaces the commented-out version
const SidebarInfo: React.FC<SidebarInfoProps> = ({ onClose }) => {
    return (
        <Box className="sidebar-info" sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
            <div className="sidebar-info__header">
                <Typography variant="h5">Information</Typography>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div style={{ padding: '1em' }}>
                <Typography>This feature is currently unavailable.</Typography>
            </div>
        </Box>
    );
};

export default SidebarInfo;

// Original component is commented out below
/*
const SidebarInfo: React.FC<SidebarInfoProps> = ({ onClose }) => {
    return (
        <Box className="sidebar-info" sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
            <div className="sidebar-info__header">
                <Typography variant="h5">Best practices</Typography>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className="sidebar-fine-tune__box">
                <Accordion elevation={0} disableGutters>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                        <StarBorderOutlinedIcon className="sidebar-fine-tune__box__icon" />
                        <Typography variant="body1">Optimal Output</Typography>
                    </AccordionSummary>
                    ... rest of component ...
                </Accordion>
            </div>
        </Box>
    );
};
*/