import { generateVideo } from '../../../../helpers/requests';

export const handleVideoGenerationService = async (
    imageKey: string,
    imageUrl: string,
    item: any,
    setVideoState: React.Dispatch<React.SetStateAction<any>>,
    setGenerationProgress: React.Dispatch<React.SetStateAction<number>>,
    setTimeRemaining: React.Dispatch<React.SetStateAction<number | null>>,
    setGeneratedVideos: React.Dispatch<React.SetStateAction<any[]>>,
    setShowVideoCompletionModal?: React.Dispatch<React.SetStateAction<boolean>>,
    setCompletedVideo?: React.Dispatch<React.SetStateAction<string | null>>
) => {
    const startTime = Date.now();
    try {
        if (!imageUrl) {
            throw new Error('No image URL provided');
        }

        const imageIndex = parseInt(imageKey.split('_')[1]);
        if (isNaN(imageIndex)) {
            throw new Error('Invalid image key format');
        }

        setVideoState({
            loading: true,
            url: null,
            error: null,
            imageKey
        });

        // Set initial time remaining to the average expected time (315 seconds)
        const initialTimeEstimate = 315;
        setGenerationProgress(0);
        setTimeRemaining(initialTimeEstimate);

        // get the original prompt or use backup if none exists
        const generationPrompt = item.imagePrompts?.[imageIndex]?.prompt || 'video of this product. zoom in and zoom out effect advertising video';

        

        // Since we don't have real-time updates anymore, we'll simulate progress
        // but with a longer total time to match the 5-6 minute expectation
        const progressInterval = setInterval(() => {
            const elapsedSeconds = Math.round((Date.now() - startTime) / 1000);
            const estimatedRemaining = Math.max(0, initialTimeEstimate - elapsedSeconds);
            
            // Update progress based on elapsed time percentage
            const progressPercentage = Math.min(90, Math.floor((elapsedSeconds / initialTimeEstimate) * 100));
            setGenerationProgress(progressPercentage);
            
            // If we're at 0 seconds but video isn't ready yet, show a different message
            if (estimatedRemaining === 0) {
                setTimeRemaining(-1); // Use -1 as a special value to indicate "almost ready"
            } else {
                setTimeRemaining(estimatedRemaining);
            }
        }, 1000); // Update every second for accurate countdown

        const videoUrl = await generateVideo(generationPrompt, imageUrl);
        
        // Clear the progress interval
        clearInterval(progressInterval);

        setTimeRemaining(null);
        setGenerationProgress(100);

        if (videoUrl) {
            const newVideo = {
                url: videoUrl,
                sourceImageKey: imageKey,
                timestamp: Date.now()
            };
            
            setGeneratedVideos(prev => [...prev, newVideo]);

            setVideoState({
                loading: false,
                url: videoUrl,
                error: null,
                imageKey
            });
            
            // Show completion modal if the function is provided
            if (setShowVideoCompletionModal && setCompletedVideo) {
                setCompletedVideo(videoUrl);
                setShowVideoCompletionModal(true);
            }
        } else {
            throw new Error('No video URL received');
        }
    } catch (error) {
        console.error('Video generation error:', error);
        setVideoState({
            loading: false,
            url: null,
            error: error instanceof Error ? error.message : 'Failed to generate video',
            imageKey
        });
        setTimeRemaining(null);
        setGenerationProgress(0);
    }
};
