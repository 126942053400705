import React, { useEffect, useState, useRef, KeyboardEvent } from 'react';
import { IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './style.scss';
import { GeneratedItem } from 'types';

interface FullscreenImageProps {
    nr: number;
    item: GeneratedItem;
    onClose: () => void;
}
const FullscreenImage: React.FC<FullscreenImageProps> = ({ nr, item, onClose }) => {
    // Filter non-empty images
    const images: string[] = item.images?.filter(Boolean) as string[];
    const [currentIndex, setCurrentIndex] = useState<number>(nr);
    const focusRef = useRef<HTMLDivElement>(null);

    // Auto-focus on mount
    useEffect(() => {
        focusRef.current?.focus();
    }, []);

    const nextImage = (): void => {
        if (currentIndex < images.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    const prevImage = (): void => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'ArrowRight') {
            nextImage();
        } else if (event.key === 'ArrowLeft') {
            prevImage();
        }
    };

    return (
        <Box
            className="full-screen-image"
            ref={focusRef}
            tabIndex={0}
            onKeyDown={handleKeyDown} // Handles keyboard navigation
            sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
            <div className="full-screen-image__header">
                <div className="full-screen-image__header__title">{item.pageData?.title}</div>
                <IconButton color="primary" size="small" onClick={onClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </div>

            <div className="full-screen-image__main" autoFocus>
                {images.length > 0 && (
                    <>
                        <IconButton color="primary" size="small" onClick={prevImage} disabled={currentIndex === 0}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <div className="full-screen-image__main-image">{images[currentIndex] && <img src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} />}</div>
                        <IconButton color="primary" size="small" onClick={nextImage} disabled={currentIndex === images.length - 1}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </>
                )}
            </div>

            <div className="full-screen-image__thumbnails">
                {images.map((thumbnail, index) => (
                    <img key={index} src={thumbnail} alt={`Thumbnail ${index + 1}`} className={`thumbnail ${index === currentIndex ? 'active' : ''}`} onClick={() => setCurrentIndex(index)} />
                ))}
            </div>
        </Box>
    );
};

export default FullscreenImage;
