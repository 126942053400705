import React from 'react';
import { Link } from 'react-router-dom';
import logoCape from '../Home/components/Topnav/assets/logoCape.svg';
import './style.scss';

const NotFound = () => {
    const currentYear = new Date().getFullYear();
    
    return (
        <div className="not-found">
            <div className="not-found__content">
                <h1>404</h1>
                <h2>Page Not Found</h2>
                <p>The page you are looking for doesn&apos;t exist or has been moved.</p>
                <Link to="/" className="btn btn-primary">
                    Go Back Home
                </Link>
            </div>
            <div className="not-found__footer">
                <span>
                    Prodshot © {currentYear} – Part of{' '}
                    <a href="https://www.bycape.io/" target="_blank" rel="noopener noreferrer">
                        <img src={logoCape} alt="Cape Logo" />
                    </a>
                </span>
            </div>
        </div>
    );
};

export default NotFound; 