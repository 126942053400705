import React, { useState, useEffect } from 'react';
import './App.css';
import Home from './controllers/Home/index.js';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ContextProvider } from './context.js';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { lightTheme, darkTheme } from './theme.js'; // Ensure both light and dark themes are imported
import ToolController from './controllers/Tool/index.js';
import NotFound from './controllers/NotFound/index.js';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />
    },
    {
        path: 'tool',
        element: <ToolController />
    },
    {
        path: '*',
        element: <NotFound />
    }
]);

function App() {
    const [isDarkMode, setIsDarkMode] = useState(null);
    useEffect(() => {
        // Detect system theme preference
        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setIsDarkMode(prefersDarkMode);
        document.body.className = prefersDarkMode ? 'dark-mode' : '';
    }, []);

    if (isDarkMode === null) {
        return null;
    }

    // Set theme based on the user's preference
    const theme = isDarkMode ? darkTheme : lightTheme;

    return (
        <ThemeProvider theme={theme}>
            <ContextProvider>
                <CssBaseline />
                <RouterProvider router={router} />
            </ContextProvider>
        </ThemeProvider>
    );
}

export default App;
