import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import './style.scss';

interface UserPromptModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: { name: string; email: string }) => void;
  isSubmitting?: boolean;
}

const UserPromptModal: React.FC<UserPromptModalProps> = ({ 
  open, 
  onClose: _, 
  onSubmit,
  isSubmitting = false 
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    setEmailError(email && !isValidEmail ? 'Please enter a valid email address' : '');
    setCanSubmit(name.trim() !== '' && email.trim() !== '' && isValidEmail);
  }, [name, email]);

  const handleSubmit = () => {
    if (canSubmit && !isSubmitting) {
      onSubmit({ name, email });
    }
  };

  return (
    <Modal 
      open={open}
      disableEscapeKeyDown
      className="user-prompt-modal"
    >
      <Box 
        className="user-prompt-modal-box"
        sx={{ 
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: '12px',
          p: 4,
          width: { xs: '90%', sm: '450px' },
          maxWidth: '500px',
          position: 'relative'
        }}
      >
        <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
          🎉 You&apos;re almost there!
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 3 }}>
          Join our exclusive beta community and unlock the full power of Prodshot! 
          We&apos;re creating stunning product images for amazing brands like yours.
        </Typography>
        
        <TextField
          label="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
          sx={{ mb: 2 }}
        />
        
        <TextField
          label="Your Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!emailError}
          helperText={emailError}
          fullWidth
          margin="normal"
          sx={{ mb: 3 }}
        />
        
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!canSubmit || isSubmitting}
          fullWidth
          sx={{ 
            boxShadow: 'none', 
            height: '44px',
            fontSize: '1rem',
            textTransform: 'none'
          }}
        >
          {isSubmitting ? <CircularProgress size={24} /> : 'Continue to Prodshot.io'}
        </Button>
        
        <Typography variant="caption" sx={{ mt: 2, display: 'block', textAlign: 'center', color: 'text.secondary' }}>
          We respect your privacy and will never share your information.
        </Typography>
      </Box>
    </Modal>
  );
};

export default UserPromptModal; 