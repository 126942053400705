import React from 'react';
import { Button, TextField, IconButton, Menu, MenuItem, useMediaQuery, useTheme, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import LinkIcon from '@mui/icons-material/Link';
import TuneIcon from '@mui/icons-material/Tune';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoIcon from '@mui/icons-material/Info';
// import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import './style.scss';

interface ToolTopBarProps {
    onSubmit: (data: { url?: string; uploadedImage?: boolean }) => void;
    onShowSidebarSetting: () => void;
    onChangeUrl: (url: string) => void;
    url: string;
    onShowInfo?: () => void;
    activeTaskCount?: number;
}

const ToolTopBar: React.FC<ToolTopBarProps> = ({ 
    onSubmit, 
    onShowSidebarSetting, 
    onChangeUrl, 
    url, 
    onShowInfo,
}) => {
    // const [showSettingItem, setShowSettingItem] = React.useState(false);
    // const [sidebarSettings, setSidebarSettings] = React.useState({
    //     format: 'square',
    //     xAxis: 50,
    //     yAxis: 50,
    //     scale: 70
    // });

    const [isValid, setIsValid] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [openMenu, setOpenMenu] = React.useState(false);
    // const [open, setOpen] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState('url');
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const errorMessage = 'Please enter a valid URL.';

    // Promise-based file reader
    const readFileAsBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    // Modify forceImageProcess to use onSubmit instead of forcing page reload
    const forceImageProcess = React.useCallback(async (file: File) => {
        // Read the file
        const base64Image = await readFileAsBase64(file);
        
        // Store in sessionStorage
        sessionStorage.setItem('uploadedImage', base64Image);
        
        // Use onSubmit instead of forcing a page reload
        onSubmit({ uploadedImage: true });
        
        // Clear selected file after it's been submitted
        setSelectedFile(null);
    }, [onSubmit]);

    // Add a direct event listener to the button as a backup approach
    React.useEffect(() => {
        // Create handleButtonClick inside the effect to properly capture the latest state
        const handleButtonClick = () => {
            // First check if we're in URL mode
            if (activeTab === 'url') {
                // Let the normal handleSubmit function handle URL validation 
                return;
            }
            
            // Only run image validation logic when in image tab
            if (selectedFile) {
                // If we have a file object, use it
                forceImageProcess(selectedFile);
                // Clear selected file after submission (handled in forceImageProcess)
            } else {
                // Check if we have an image in sessionStorage already
                const storedImage = sessionStorage.getItem('uploadedImage');
                if (storedImage) {
                    // Use onSubmit instead of forcing a page reload
                    onSubmit({ uploadedImage: true });
                    // Clear the selected file
                    setSelectedFile(null);
                } 
            }
        };
        
        const attachButtonListener = () => {
            setTimeout(() => {
                const button = document.getElementById('generate-button');
                if (button) {
                    button.addEventListener('click', handleButtonClick);
                } else {
                    attachButtonListener();
                }
            }, 500);
        };
        
        attachButtonListener();
        
        return () => {
            const button = document.getElementById('generate-button');
            if (button) {
                button.removeEventListener('click', handleButtonClick);
            }
        };
    }, [activeTab, selectedFile, forceImageProcess, onSubmit]);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };

    const handleMenuClose = () => {
        setOpenMenu(false);
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedFile(e.target.files[0]);
            setActiveTab('image');
            
            // Don't auto-process the image, wait for the Generate button click
            try {
                const file = e.target.files[0];
                const base64Image = await readFileAsBase64(file);
                
                // Just store in sessionStorage, don't call onSubmit yet
                sessionStorage.setItem('uploadedImage', base64Image);
            } catch (error) {
                alert('Failed to process image. Please try again.');
            }
        }
    };

    const handleSubmit = async () => {
        if (activeTab === 'url') {
            const urlRegex = new RegExp('^(http|https)://[^\\s/$.?#].[^\\s]*$', 'i');
            const isValid = urlRegex.test(url);
            setIsValid(isValid);
            if (!isValid) return;

            // Clear any previously uploaded image from sessionStorage
            sessionStorage.removeItem('uploadedImage');
            
            onSubmit({ url: url });
        } else if (activeTab === 'image') {
            if (selectedFile) {
                // Process the image
                try {
                    forceImageProcess(selectedFile);
                } catch (error) {
                    alert('Failed to process image. Please try again.');
                }
            } else {
                // Check if we have an image in sessionStorage already
                const storedImage = sessionStorage.getItem('uploadedImage');
                if (storedImage) {
                    // Use onSubmit directly if we have a stored image
                    onSubmit({ uploadedImage: true });
                    // Clear selected file after it's been submitted
                    setSelectedFile(null);
                } 
            }
        }
    };

    return (
        <Box className="tool-top-bar" sx={{ borderBottom: 'none' }}>
            {/* <Collapse in={open} className="tool-top-bar-alert">
                <Alert
                    severity="info"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{
                        borderRadius: 0,
                        backgroundColor: theme.palette.background.info
                    }>
                    <Typography variant="body2">Beta Feature We are working hard to make it faster and better!</Typography>
                </Alert>
            </Collapse> */}

            <form
                className="tool-top-bar-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                style={{ display: 'flex', width: '100%' }}>
                <div className="input-group">
                    <div className="option-buttons">
                        <ToggleButtonGroup
                            value={activeTab}
                            exclusive
                            onChange={(_, newValue) => {
                                if (newValue !== null) {
                                    setActiveTab(newValue);
                                    if (newValue === 'image') {
                                        fileInputRef.current?.click();
                                    } else if (newValue === 'url') {
                                        // Clear selected file and remove from sessionStorage when switching to URL mode
                                        setSelectedFile(null);
                                        sessionStorage.removeItem('uploadedImage');
                                        // Alert the user about the mode change
                                        setTimeout(() => {
                                            // Use a small timeout to allow the UI to update first
                                            console.log('Switched to URL mode, cleared image data');
                                        }, 100);
                                    }
                                }
                            }}
                            size="small"
                        >
                            <ToggleButton value="url">
                                <LinkIcon sx={{ mr: 1 }} />
                                URL
                            </ToggleButton>
                            <ToggleButton value="image">
                                <ImageIcon sx={{ mr: 1 }} />
                                Image
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>

                    <TextField
                        placeholder={activeTab === 'url' ? "e.g. https://www.webshop.com/product-1" : "Select an image..."}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={activeTab === 'url' ? url : selectedFile?.name || ''}
                        error={!isValid && activeTab === 'url'}
                        helperText={
                            !isValid && activeTab === 'url' 
                                ? errorMessage 
                                : activeTab === 'image' && selectedFile 
                                    ? "Click 'Generate' to process image"
                                    : ""
                        }
                        onChange={(e) => activeTab === 'url' && onChangeUrl(e.target.value)}
                        InputProps={{
                            readOnly: activeTab === 'image',
                            startAdornment: (
                                <InputAdornment position="start">
                                    {activeTab === 'url' ? <LinkIcon /> : <ImageIcon />}
                                </InputAdornment>
                            )
                        }}
                    />

                    <Button
                        id="generate-button"
                        variant="contained"
                        color="primary"
                        sx={{
                            boxShadow: 'none',
                            height: '40px',
                            ...(activeTab === 'image' && selectedFile ? {
                                animation: 'pulse 1.5s infinite',
                                '@keyframes pulse': {
                                    '0%': {
                                        boxShadow: '0 0 0 0 rgba(233, 30, 99, 0.4)'
                                    },
                                    '70%': {
                                        boxShadow: '0 0 0 10px rgba(233, 30, 99, 0)'
                                    },
                                    '100%': {
                                        boxShadow: '0 0 0 0 rgba(233, 30, 99, 0)'
                                    }
                                }
                            } : {})
                        }}
                        onClick={() => handleSubmit()}>
                        Generate
                    </Button>

                    {isSmallScreen ? (
                        <IconButton onClick={handleMenuClick} sx={{ boxShadow: 'none' }}>
                            <MoreVertIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            onClick={onShowSidebarSetting}
                            sx={{
                                boxShadow: 'none',
                                height: '40px'
                            }}>
                            <TuneIcon />
                        </IconButton>
                    )}
                </div>
            </form>

            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                sx={{
                    mt: 1
                }}>
                <MenuItem
                    onClick={() => {
                        onShowSidebarSetting();
                        handleMenuClose();
                    }}>
                    <TuneIcon sx={{ mr: 1 }} /> Settings
                </MenuItem>
                {onShowInfo && (
                    <MenuItem
                        onClick={() => {
                            onShowInfo();
                            handleMenuClose();
                        }}>
                        <InfoIcon sx={{ mr: 1 }} /> About
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};

export default ToolTopBar;