import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#ED39D3'
        },
        secondary: {
            main: '#F3E5F5'
        },
        background: {
            paper: '#ffffff',
            default: '#F3F5F8'
        },
        text: {
            primary: '#333333',
            secondary: '#919297'
        },
        action: {
            active: '#000000B8'
        },
        divider: '#E0E0E0'
    },
    shape: {
        borderRadius: 8
    },
    typography: {
        fontFamily: 'Inter, Arial, sans-serif',
        h1: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '32px'
        },
        body1: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '16px'
        },
        body2: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20.02px',
            letter: '0.17px'
        },
        h5: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '14px'
        },
        h3: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '16px'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '0.8em',
                    textTransform: 'none'
                }
            }
        },

        MuiAlert: {
            styleOverrides: {
                root: {
                    border: '1px solid #7B1FA2'
                },
                message: {
                    color: '#7B1FA2'
                },
                icon: {
                    color: '#7B1FA2 !important'
                }
            }
        }
    }
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#ED39D3'
        },
        secondary: {
            main: '#342937'
        },
        background: {
            paper: '#24272D',
            default: '#171A1E',
            info: '#EB3FAA80'
        },
        text: {
            primary: '#ffffff',
            secondary: '#FFFFFFB2'
        },
        action: {
            active: '#FFFFFF8F'
        }
    },
    shape: {
        borderRadius: 8
    },
    typography: {
        fontFamily: 'Inter, Arial, sans-serif',
        h1: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '32px'
        },
        body1: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '16px',
            color: '#ffffff'
        },
        h5: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '14px',
            color: '#ffffff'
        },
        h3: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '16px',
            color: '#ffffff'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '0.8em',
                    textTransform: 'none'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    border: '2px solid #EB3FAA'
                },
                message: {
                    color: '#EB3FAA'
                },
                icon: {
                    color: '#EB3FAA !important'
                }
            }
        }
    }
});

export { lightTheme, darkTheme };
