import React, { useEffect, useState } from 'react';
import { Button, IconButton, TextField, Typography, Box, Accordion } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './style.scss';
import { GeneratedItem } from 'types';

interface SidebarProps {
    item: GeneratedItem;
    activeImage: number;
    setActiveImage: React.Dispatch<React.SetStateAction<number>>;
    onClose: () => void;
    onSubmit: (data: any) => void;
}

// const MAX_PROMPTS = 4;

const SidebarFineTune: React.FC<SidebarProps> = ({ item, activeImage, setActiveImage, onClose, onSubmit }) => {
    const [promptContent, setPromptContent] = useState<string[]>([]);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        if (item.imagePrompts) {
            const allPrompts = item.imagePrompts.map((img) => img.prompt);
            setPromptContent(allPrompts);
        }
    }, [item.imagePrompts]);

    const handlePromptChange = (index: number, value: string) => {
        const updatedPrompts = [...promptContent];
        updatedPrompts[index] = value;
        setPromptContent(updatedPrompts);
    };

    return (
        <Box className="sidebar-fine-tune" sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
            <div className="sidebar-fine-tune__header">
                <Typography>{item.pageData?.title}</Typography>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <Accordion elevation={0} disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    <ImageSearchIcon className="sidebar-fine-tune__box__icon" />
                    <Typography>Select image</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="sidebar-fine-tune__box__product-images">
                        {item?.pageData?.productImages?.map((imageItem, i) => (
                            <div key={i} className={'sidebar-fine-tune__box__product-images__item ' + (i === activeImage ? 'active' : '')} onClick={() => setActiveImage(i)}>
                                <img className="sidebar-fine-tune__box__product-images__content" src={imageItem.src} />
                            </div>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion elevation={0} disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    <ShortcutIcon className="sidebar-fine-tune__box__icon" />
                    <Typography> Prompt</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box display="flex" flexDirection="column" gap={2}>
                        {promptContent.slice(0, showAll ? promptContent.length : 4).map((prompt, index) => (
                            <TextField
                                key={index}
                                multiline
                                rows={4}
                                label={`Image ${index + 1}`}
                                onChange={(e) => handlePromptChange(index, e.target.value)}
                                value={prompt}
                                fullWidth
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    style: {
                                        fontSize: 14
                                    }
                                }}
                            />
                        ))}
                        {promptContent.length > 4 && (
                            <Button onClick={() => setShowAll(!showAll)} variant="text" size="small">
                                {showAll ? 'See Less' : 'See All'}
                            </Button>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>

            <div className="sidebar-fine-tune__footer">
                <Button
                    variant="contained"
                    fullWidth
                    sx={{
                        boxShadow: 'none'
                    }}
                    onClick={() => {
                        onSubmit({
                            ...item,
                            productImage: item?.pageData?.productImages[activeImage]?.src,
                            prompt: promptContent,
                            imagePrompts: promptContent.map((prompt) => ({ prompt }))
                        });
                    }}>
                    Generate
                </Button>
            </div>
        </Box>
    );
};

export default SidebarFineTune;
