import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    LinearProgress,
    Typography,
    useMediaQuery,
    useTheme,
    Avatar,
    ButtonGroup,
    Skeleton,
    ToggleButton,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Dialog,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Badge
} from '@mui/material';
import './style.scss';
import { handleVideoGenerationService } from '../VideoGeneration';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import FullscreenImage from '../FullscreenImage';
import TuneIcon from '@mui/icons-material/Tune';
import IosShareIcon from '@mui/icons-material/IosShare';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tooltip from '@mui/material/Tooltip';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { GeneratedItem } from 'types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import VideocamIcon from '@mui/icons-material/Videocam';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

interface ListItemProps {
    item: GeneratedItem;
    onRemove: () => void;
    onFinetuneSetting: () => void;
    onDeleteImage: (uuid: string, imageKey: string) => void;
    onOneRegenerate: (item: GeneratedItem, index: number) => void;
    activeImage: number;
    setActiveImage: React.Dispatch<React.SetStateAction<number>>;
    cancelButton?: () => void;
    onGenerateMore: (data: any) => void;
    handleDetails: () => void;
    updateVideos: (uuid: string, videos: GeneratedVideo[]) => void;
}

interface VideoState {
    loading: boolean;
    url: string | null;
    error: string | null;
    imageKey: string | null;
}

interface GeneratedVideo {
    url: string;
    sourceImageKey: string;
    timestamp: number;
}

const formatTime = (timeInMilliseconds?: number) => {
    if (!timeInMilliseconds) return '';

    const totalSeconds = Math.floor(timeInMilliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
};

const ListItemFooter: React.FC<{
    item: GeneratedItem;
    isMobile: boolean;
    isComplete: boolean;
    dialogType: 'delete' | 'cancel' | null;
    onGenerateMore: (data: any) => void;
    onFinetuneSetting: () => void;
    onCancelGeneration?: () => void;
    handleOpenDialog: (type: 'delete' | 'cancel') => void;
    handleCloseDialog: () => void;
    activeImage: number;
}> = ({
    item,
    isMobile,
    isComplete,
    dialogType,
    onGenerateMore,
    onFinetuneSetting,
    onCancelGeneration,
    handleOpenDialog,
    handleCloseDialog,
    activeImage
}) => {
    // Create a wrapper for cancel action that also closes the dialog
    const handleCancelClick = () => {
        if (onCancelGeneration) {
            onCancelGeneration();
            handleCloseDialog();
        }
    };

    // Clear any leftover cancel dialog when generation is not active
    useEffect(() => {
        if (!item.loading && dialogType === 'cancel') {
            handleCloseDialog();
        }
    }, [item.loading, dialogType, handleCloseDialog]);

    // Show "Generate more" button if generation is complete or not loading
    if (isComplete || !item.loading) {
        return (
            <div className="tool-list-item__footer">
                <ButtonGroup
                    size="small"
                    disableElevation
                    variant="outlined"
                    aria-label="Generation controls">
                    <Button
                        onClick={() => {
                            handleCloseDialog(); // Ensure dialog is closed
                            const selectedImage = item?.pageData?.productImages?.[activeImage];
                            onGenerateMore({
                                ...item,
                                productImage: selectedImage?.src || item.productImage, // Fallback to main productImage if needed
                                activeImage: activeImage,
                                preprocessedImages: null // Clear preprocessed images to force using the new image
                            });
                        }}>
                        Generate more
                    </Button>
                    <Button onClick={onFinetuneSetting}>
                        <TuneIcon fontSize="small" />
                    </Button>
                </ButtonGroup>
            </div>
        );
    }

    // Show cancel button only during active generation
    if (item.loading && onCancelGeneration) {
        return (
            <div className="tool-list-item__footer">
                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth={isMobile}
                    onClick={() => handleOpenDialog('cancel')}>
                    Cancel Generation
                </Button>
                <Dialog
                    open={dialogType === 'cancel' && item.loading}
                    onClose={handleCloseDialog}
                    aria-labelledby="cancel-dialog-title"
                    aria-describedby="cancel-dialog-description">
                    <DialogTitle id="cancel-dialog-title">
                        <Typography variant="body1">
                            Are you sure you want to cancel generation?
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="cancel-dialog-description">
                            <Typography variant="caption">
                                By clicking on cancel, the generation will be stopped.
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={handleCloseDialog} 
                            sx={{ color: 'text.primary' }}>
                            Keep Generation
                        </Button>
                        <Button 
                            onClick={handleCancelClick}
                            autoFocus 
                            variant="outlined">
                            Stop Generating
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    return null;
};

const ListItem: React.FC<ListItemProps> = ({ item, onRemove, onFinetuneSetting, onDeleteImage, onOneRegenerate, activeImage, setActiveImage, cancelButton, onGenerateMore, handleDetails, updateVideos }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [showFullscreenImage, setShowFullscreenImage] = useState(false);
    const [fullscreenImageIndex, setFullscreenImageIndex] = useState<number | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
    const open = Boolean(anchorEl);
    useEffect(() => {
        if (item.loading) {
            const intervalId = setInterval(() => {
                setLoadingMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
            }, 10000);

            return () => clearInterval(intervalId);
        }
    }, [item.loading]);

    const loadingMessages = ['Bringing your product to life with the perfect background...', 'Your product is getting the star treatment!', 'Enhancing your product image to look fabulous', 'Giving your product the glow-up it deserves!'];
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleShareWithClose = () => {
        handleShare();
        handleClose();
    };

    const handleFinetuneWithClose = () => {
        onFinetuneSetting();
        handleClose();
    };

    const [showVideos, setShowVideos] = useState(false);
    const [generatedVideos, setGeneratedVideos] = useState<GeneratedVideo[]>(
        item.generatedVideos || []
    );
    const [videoState, setVideoState] = useState<VideoState>({
        loading: false,
        url: null,
        error: null,
        imageKey: null
    });
    const [timeRemaining, setTimeRemaining] = useState<number | null>(null);

    const [generationProgress, setGenerationProgress] = useState<number>(0);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [dialogType, setDialogType] = React.useState<null | 'delete' | 'cancel'>(null);
    const handleOpenDialog = (type: 'delete' | 'cancel') => setDialogType(type);
    const handleCloseDialog = () => setDialogType(null);
    const handleDownloadImage = async (imageUrl: string) => {
        try {
            const response = await fetch(imageUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch image: ${imageUrl}`);
            }
            const imageBlob = await response.blob();
            const fileName = imageUrl.split('/').pop() || 'image';

            saveAs(imageBlob, fileName);
        } catch (error) {
            console.error('Download error:', error);
        }
    };
    const handleDownload = async () => {
        const zip = new JSZip();
        const urlArray: string[] = [];
        for (let i = 1; i < 5; i++) {
            const imageKey = `image_${i}`;
            if (item[imageKey]) {
                urlArray.push(item[imageKey]!);
            }
        }

        const fetchImage = async (url: string): Promise<Blob> => {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Failed to fetch ${url}`);
            }
            return response.blob();
        };

        try {
            for (const url of urlArray) {
                const imageBlob = await fetchImage(url);
                const fileName = url.split('/').pop() || 'image';
                zip.file(fileName, imageBlob);
            }

            const content = await zip.generateAsync({ type: 'blob' });
            saveAs(content, 'images.zip');
        } catch (error) {
            console.error(error);
        }
    };

    const handleShare = () => {
        const shareLink = `https://acceptance.prodshot.io/tool?import=${item.uuid}`;
        navigator.clipboard.writeText(shareLink);
        alert('Copied to clipboard');
    };
    const [showVideoCompletionModal, setShowVideoCompletionModal] = useState(false);
    const [completedVideo, setCompletedVideo] = useState<string | null>(null);
    const [isNewlyGenerated, setIsNewlyGenerated] = useState(false);

    const handleVideoGeneration = useCallback(
        (imageKey: string, imageUrl: string) => {
            handleVideoGenerationService(
                imageKey,
                imageUrl,
                item,
                setVideoState,
                setGenerationProgress,
                setTimeRemaining,
                setGeneratedVideos,
                (show) => {
                    setShowVideoCompletionModal(show);
                    setIsNewlyGenerated(true);
                },
                setCompletedVideo
            );
        },
        [item]
    );

    const handleVideoDownload = (videoUrl: string, index: number) => {
        const link = document.createElement('a');
        link.href = videoUrl;
        link.download = `video-${index + 1}.mp4`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDeleteVideo = (timestamp: number, sourceImageKey: string) => {
        const updatedVideos = generatedVideos.filter(
            (v) => v.timestamp !== timestamp || v.sourceImageKey !== sourceImageKey
        );
        setGeneratedVideos(updatedVideos);
        // The useEffect will handle updating localStorage
    };

    const VideoGrid = () => (
        <div className="tool-list-item__content__right">
            {generatedVideos.length === 0 ? (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 4
                    }}>
                    <Typography variant="body1" color="text.secondary">
                        No videos generated yet. Generate videos from the images above.
                    </Typography>
                </Box>
            ) : (
                generatedVideos.map((video, index) => (
                    <div 
                        key={`${video.sourceImageKey}-${video.timestamp}`} 
                        className="tool-list-item__content__video" 
                        onMouseEnter={() => setHoveredIndex(index)} 
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <video 
                            autoPlay 
                            loop 
                            muted 
                            playsInline 
                            src={video.url} 
                            onClick={() => {
                                setCompletedVideo(video.url);
                                setIsNewlyGenerated(false);
                                setShowVideoCompletionModal(true);
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                        {hoveredIndex === index && (
                            <div className="tool-list-item__hover-content">
                                <Tooltip title="Download">
                                    <IconButton
                                        size="small"
                                        onClick={() => handleVideoDownload(video.url, index)}
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.background.paper,
                                            borderRadius: '10px',
                                            '&:hover': {
                                                backgroundColor: (theme) => theme.palette.background.paper
                                            }
                                        }}>
                                        <FileDownloadOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton
                                        size="small"
                                        onClick={() => handleDeleteVideo(video.timestamp, video.sourceImageKey)}
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.background.paper,
                                            borderRadius: '10px',
                                            '&:hover': {
                                                backgroundColor: (theme) => theme.palette.background.paper
                                            }
                                        }}>
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                ))
            )}
        </div>
    );

    const isComplete = useCallback(() => {
        const hasAllImages = item.images?.filter(Boolean).length === 4;
        const isNotLoading = !item.loading;
        const hasNoErrors = !item.error;
        const hasAtLeastOneImage = (item.images ?? []).filter(Boolean).length > 0;
        
        // Show "Generate more" if either:
        // 1. All images are generated successfully, OR
        // 2. Generation was cancelled but we have at least one image
        return (hasAllImages && isNotLoading && hasNoErrors) || 
               (hasAtLeastOneImage && isNotLoading);
    }, [item.images, item.loading, item.error]);

    const [videoConfirmOpen, setVideoConfirmOpen] = useState(false);
    const [selectedImageForVideo, setSelectedImageForVideo] = useState<{key: string, url: string} | null>(null);

    // Add this component to render the video completion modal
    const VideoCompletionModal = () => (
        <Dialog
            open={showVideoCompletionModal}
            onClose={() => setShowVideoCompletionModal(false)}
            maxWidth="md"
            aria-labelledby="video-completion-dialog-title"
        >
            <DialogTitle id="video-completion-dialog-title">
                {isNewlyGenerated ? "🎉 Your Video is Ready!" : "Video Preview"}
                <IconButton
                    aria-label="close"
                    onClick={() => setShowVideoCompletionModal(false)}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {isNewlyGenerated && (
                    <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                        Your video has been successfully generated and is ready to use in your marketing campaigns!
                    </Typography>
                )}
                
                {completedVideo && (
                    <Box sx={{ mt: 2, width: '100%', textAlign: 'center' }}>
                        <video 
                            controls 
                            autoPlay 
                            loop 
                            style={{ maxWidth: '100%', maxHeight: '70vh' }}
                            src={completedVideo}
                        />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                {isNewlyGenerated ? (
                    <>
                        <Button 
                            onClick={() => {
                                setShowVideoCompletionModal(false);
                                setShowVideos(true);
                            }}
                        >
                            View All Videos
                        </Button>
                        <Button 
                            variant="contained"
                            onClick={() => setShowVideoCompletionModal(false)}
                        >
                            Close
                        </Button>
                    </>
                ) : (
                    <Button 
                        variant="contained"
                        onClick={() => setShowVideoCompletionModal(false)}
                    >
                        Close
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );

    useEffect(() => {
        if (!item.pageData?.productImages || item.pageData.productImages.length === 0) {
            console.warn('No product images available for item:', item.uuid);
        }
    }, [item]);

    useEffect(() => {
        if (generatedVideos.length > 0 && JSON.stringify(generatedVideos) !== JSON.stringify(item.generatedVideos)) {
            updateVideos(item.uuid, generatedVideos);
        }
    }, [generatedVideos, item.uuid]);

    return (
        <Box className="tool-list-item" sx={{ backgroundColor: (theme) => theme.palette.background.paper, borderColor: (theme) => theme.palette.divider }}>
            {showFullscreenImage && fullscreenImageIndex !== null && (
                <FullscreenImage
                    nr={fullscreenImageIndex}
                    item={item}
                    onClose={() => {
                        setShowFullscreenImage(false);
                        setFullscreenImageIndex(null);
                    }}
                />
            )}

            {item.loading && <LinearProgress variant="determinate" value={(item.loadingProgress! / 6) * 100} />}
            {videoState.loading && (
                <Box sx={{ mt: 2, px: 2 }}>
                    <Typography variant="body2" color="text.secondary" fontWeight="medium">
                        Generating video... 
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {timeRemaining === null ? 'Processing...' : 
                         timeRemaining === -1 ? 'Almost ready, final polishing details are being applied...' :
                         `Estimated time remaining: ${Math.floor(timeRemaining / 60)}m ${timeRemaining % 60}s`}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        Video generation typically takes 5-6 minutes to complete
                    </Typography>

                    <LinearProgress variant="determinate" value={generationProgress} sx={{ mt: 1 }} />
                </Box>
            )}
            <div className="tool-list-item__header">
                <Avatar variant="rounded" sx={{ backgroundColor: (theme) => theme.palette.background.default }} className="tool-list-item__header__icon">
                    {item.loading && <CircularProgress size={24} />}
                    {!item.loading && !item.error && <CheckCircleOutlineIcon fontSize="inherit" color="success" />}
                    {item.error && <ErrorOutlineIcon fontSize="inherit" color="error" />}
                </Avatar>

                <div className="tool-list-item__header__context">
                    <Typography variant="h5">{item.pageData?.title}</Typography>
                    {item.error && <Typography variant="h5">Failed to scrape data, please insert different URL</Typography>}
                    <div className="tool-list-item__header__position">
                        {item.loading && (
                            <Typography variant="caption" className="tool-list-item__header__loading">
                                {loadingMessages[loadingMessageIndex]}
                            </Typography>
                        )}
                        {!item.loading && item.totalTime && (
                            <Typography 
                                variant="caption" 
                                sx={{ 
                                    color: 'text.secondary',
                                    fontWeight: 'medium'
                                }}
                            >
                                {`Total Time: ${formatTime(item.totalTime)}`}
                            </Typography>
                        )}
                        <a href={item.url} className="tool-list-item__header__url" target="_blank" rel="noreferrer">
                            <Typography variant="caption">{item.url}</Typography>
                        </a>
                    </div>
                </div>

                <div className="tool-list-item__header__actions">
                    <IconButton size="small" onClick={() => setShowVideos(!showVideos)}>
                        {showVideos ? (
                            <Tooltip title="Generated images">
                                <CameraAltIcon />
                            </Tooltip>
                        ) : (
                            <Badge 
                                badgeContent={generatedVideos.length} 
                                showZero
                                sx={{
                                    '& .MuiBadge-badge': {
                                        zIndex: 0,
                                    }
                                }}
                            >
                                <Tooltip title="Generated videos">
                                    <VideocamIcon />
                                </Tooltip>
                            </Badge>
                        )}
                    </IconButton>

                    <Tooltip title="Remove">
                        <IconButton size="small" onClick={() => handleOpenDialog('delete')}>
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Dialog open={dialogType === 'delete'} onClose={handleCloseDialog} aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">
                            <Typography variant="body1">Are you sure you want to remove this item?</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="caption">By clicking on Delete, this item will be removed.</Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} sx={{ color: 'text.primary' }}>
                                Cancel
                            </Button>
                            <Button onClick={onRemove} autoFocus variant="outlined">
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Tooltip title="More">
                        <IconButton size="small" onClick={handleClick}>
                            <MoreHorizIcon sx={{ color: (theme) => theme.palette.action.active }} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}>
                        <MenuItem onClick={handleDownload}>
                            <ListItemIcon>
                                <FileDownloadOutlinedIcon fontSize="small" />{' '}
                            </ListItemIcon>
                            <ListItemText> Download</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleShareWithClose}>
                            <ListItemIcon>
                                <IosShareIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText> Share</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleFinetuneWithClose}>
                            <ListItemIcon>
                                <TuneIcon fontSize="small" />{' '}
                            </ListItemIcon>
                            <ListItemText> Setting</ListItemText>
                        </MenuItem>
                    </Menu>

                    <Tooltip title="Details">
                        <ToggleButton value="check" size="small" onClick={handleDetails}>
                            <InfoOutlinedIcon fontSize="small" />
                        </ToggleButton>
                    </Tooltip>
                </div>
            </div>

            <div className="tool-list-item__content">
                <Box className="tool-list-item__content__left">
                    <Tooltip title="Select scraped image for generating new.">
                        <div className="tool-list-item__content__product-images">
                            {item.pageData?.productImages?.map((imageItem, i) => (
                                <div key={i} className={`tool-list-item__content__product-images__item ${i === activeImage ? 'active' : ''}`} onClick={() => setActiveImage(i)}>
                                    <img className="tool-list-item__content__product-images__content" src={imageItem.src} alt="" />
                                </div>
                            ))}
                        </div>
                    </Tooltip>
                    <Box onClick={() => setShowFullDescription(!showFullDescription)} className={`tool-list-item__content__description ${showFullDescription ? 'full' : ''}`} sx={{ color: (theme) => theme.palette.text.primary }}>
                        {item.pageData?.description}
                    </Box>
                </Box>
                {showVideos ? (
                    <VideoGrid />
                ) : (
                    <div className="tool-list-item__content__right">
                        {/* Map through all possible image slots, including pending ones */}
                        {Array.from({ length: item.images?.length || 0 }).map((_, index) => {
                            const imageKey = `image_${index}`;
                            const image = item.images?.[index];
                            const isImageLoading = item.loading && item.loadingImages?.[index] === true;

                            // Only render the container div if there's an image or if it's currently loading
                            return (isImageLoading || image) ? (
                                <div
                                    key={`slot-${index}`}
                                    className="tool-list-item__content__image"
                                    onClick={() => {
                                        // Only allow full-screen preview if the image is not currently loading
                                        if (!isImageLoading && image) {
                                            setShowFullscreenImage(true);
                                            setFullscreenImageIndex(index);
                                        }
                                    }}
                                >
                                    {isImageLoading ? (
                                        // If this image is still generating, show a skeleton
                                        <Skeleton
                                            className="tool-list-item__content__image__skeleton"
                                            variant="rectangular"
                                            animation="wave"
                                        />
                                    ) : image ? (
                                        <>
                                            <img
                                                className="tool-list-item__content__image__content"
                                                src={image}
                                                alt={`Image ${index}`}
                                            />
                                            <div className="tool-list-item__hover-content">
                                                <Tooltip title="Delete">
                                                    <IconButton
                                                        size="small"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            onDeleteImage(item.uuid ?? '', imageKey);
                                                        }}
                                                        sx={{
                                                            backgroundColor: (theme) => theme.palette.background.paper,
                                                            borderRadius: '10px',
                                                            '&:hover': {
                                                                backgroundColor: (theme) => theme.palette.background.paper,
                                                            },
                                                        }}
                                                    >
                                                        <DeleteOutlineOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Regenerate">
                                                    <IconButton
                                                        size="small"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            // Call the parent's regenerate callback for just this image
                                                            onOneRegenerate(item, index);
                                                        }}
                                                        sx={{
                                                            backgroundColor: (theme) => theme.palette.background.paper,
                                                            borderRadius: '10px',
                                                            '&:hover': {
                                                                backgroundColor: (theme) => theme.palette.background.paper,
                                                            },
                                                        }}
                                                    >
                                                        <AutorenewIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Generate Video (takes 5-6 minutes)">
                                                    <IconButton
                                                        size="small"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            if (image && typeof image === 'string') {
                                                                setVideoConfirmOpen(true);
                                                                setSelectedImageForVideo({ key: imageKey, url: image });
                                                            } else {
                                                                setVideoState((prev) => ({
                                                                    ...prev,
                                                                    error: 'Invalid image data for video generation',
                                                                }));
                                                            }
                                                        }}
                                                        disabled={videoState.loading}
                                                        sx={{
                                                            backgroundColor: (theme) => theme.palette.background.paper,
                                                            borderRadius: '10px',
                                                            '&:hover': {
                                                                backgroundColor: (theme) => theme.palette.background.paper,
                                                            },
                                                        }}
                                                    >
                                                        {videoState.loading && videoState.imageKey === imageKey ? (
                                                            <CircularProgress size={24} />
                                                        ) : (
                                                            <VideocamIcon />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Download">
                                                    <IconButton
                                                        size="small"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleDownloadImage(image);
                                                        }}
                                                        sx={{
                                                            backgroundColor: (theme) => theme.palette.background.paper,
                                                            borderRadius: '10px',
                                                            '&:hover': {
                                                                backgroundColor: (theme) => theme.palette.background.paper,
                                                            },
                                                        }}
                                                    >
                                                        <FileDownloadOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            ) : null;
                        })}
                    </div>
                )}
            </div>

            <Dialog
                open={videoConfirmOpen}
                onClose={() => setVideoConfirmOpen(false)}
                aria-labelledby="video-generation-dialog-title"
                aria-describedby="video-generation-dialog-description"
            >
                <DialogTitle id="video-generation-dialog-title">
                    Generate Video
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="video-generation-dialog-description">
                        <Typography variant="body1" gutterBottom>
                            Video generation takes approximately <strong>5-6 minutes</strong> to complete.
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            You can continue using the application while the video is being generated.
                            You&apos;ll be notified when it&apos;s ready.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setVideoConfirmOpen(false)}>Cancel</Button>
                    <Button 
                        variant="contained"
                        onClick={() => {
                            setVideoConfirmOpen(false);
                            if (selectedImageForVideo) {
                                handleVideoGeneration(selectedImageForVideo.key, selectedImageForVideo.url);
                            }
                        }}
                    >
                        Generate Video
                    </Button>
                </DialogActions>
            </Dialog>

            <VideoCompletionModal />

            <ListItemFooter
                item={item}
                isMobile={isMobile}
                isComplete={isComplete()}
                dialogType={dialogType}
                onGenerateMore={onGenerateMore}
                onFinetuneSetting={onFinetuneSetting}
                onCancelGeneration={cancelButton}
                handleOpenDialog={handleOpenDialog}
                handleCloseDialog={handleCloseDialog}
                activeImage={activeImage}
            />
        </Box>
    );
};

export default ListItem;
