import React from 'react';
import emptystate from './assets/empty-state.png';
import { Typography, Box } from '@mui/material';

export default function EmptyState() {
    return (
        <Box
            className="tool__placeholder"
            display="flex"
            flexDirection="column"
            alignItems="center"
            alignSelf="center"
            justifyContent="center"
            sx={{
                width: '100%',
                textAlign: 'center'
            }}>
            <img src={emptystate} alt="Empty state" style={{ maxWidth: 'fit-content', height: 'auto' }} />
            <Box sx={{ mt: 2 }}>
                <Typography variant="h4">No generations yet!</Typography>
                <Typography variant="body1" color="textSecondary">
                    Generate captivating visuals from your product URL. Please enter it in the top bar above.
                </Typography>
            </Box>
        </Box>
    );
}
