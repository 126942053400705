import React, { useState, useRef } from 'react';
import './style.scss';
import SidebarInfo from './components/SidebarInfo';
import ToolTopBar from './components/ToolTopBar';
import ListItem from './components/ListItem';
import SidebarFineTune from './components/SidebarFineTune';
import SidebarDetails from './components/SidebarDetails';
import SidebarSetting from './SidebarSetting';
import UserPromptModal from './components/UserPromptModal';
import { 
    generateCopy, 
    parsePage, 
    createImages, 
    preprocessImages, 
    createUser,
    uploadImage 
} from '../../helpers/requests';
import EmptyState from './components/EmptyState';
import { v4 as uuidv4 } from 'uuid';

// Task type constants for better maintainability
const TASK_TYPES = {
    URL: 'url',
    UPLOAD: 'upload',
    REGENERATE: 'regenerate',
    GENERATE_ONE: 'generate_one',
    GENERATE_MORE: 'generate_more'
};

const ToolController = () => {
    const getStorageData = () => {
        let list = localStorage.getItem('overview') ? JSON.parse(localStorage.getItem('overview')) : [];

        if (list.length > 0) {
            list.forEach((item) => {
                if (item.loading) {
                    item.loading = false;
                }
            });
        }

        return list;
    };

    const [url, setUrl] = React.useState('');
    const [overview, setOverview] = React.useState(getStorageData());
    const [showDetails, setShowDetails] = React.useState(false);
    const [activeImage, setActiveImage] = useState({});
    
    // Task handling system - concurrent processing
    const cancelTasks = useRef({});  // Map of uuid to cancellation flag
    const runningTasks = useRef(new Set()); // Track running tasks by UUID
    const [activeTaskCount, setActiveTaskCount] = useState(0); // Track number of running tasks
    
    const loading = useRef(false);
    const [showInfo, setShowInfo] = React.useState(false);
    const [showSidebarSetting, setShowSidebarSetting] = React.useState(false);
    const handleSidebarSettingOpen = () => setShowSidebarSetting(true);
    const [sidebarSettings, setSidebarSettings] = React.useState({});
    const [isFineTuneVisible, setIsFineTuneVisible] = React.useState(false);
    const [currentFineTuneItem, setCurrentFineTuneItem] = React.useState(null);
    const handleSettingSidebarSave = (updatedSettings) => {
        setSidebarSettings(updatedSettings);
        setShowSidebarSetting(false);
    };
    const [isAlertVisible] = React.useState(true);
    const [showUserModal, setShowUserModal] = useState(false);
    const [isSubmittingUser, setIsSubmittingUser] = useState(false);

    /**
     * Cancel image generation for a specific task
     */
    const cancelImageGeneration = (uuid) => {
        // Set the cancellation flag for this specific task
        cancelTasks.current[uuid] = true;

        // Immediately stop loading and reset progress for the item
        updateItem(uuid, {
            loading: false,
            loadingProgress: 0
        });
        
        // Remove from running tasks
        runningTasks.current.delete(uuid);
        setActiveTaskCount(runningTasks.current.size);
    };

    /**
     * Start a new task immediately with concurrent processing
     * @param {string} taskType - Type of task to run
     * @param {Object} item - Item to process
     * @param {Object} additionalData - Additional data for the task
     * @param {number} priority - Priority level (1 = highest, 5 = lowest)
     * @returns {Promise<void>}
     */
    const startTask = async (taskType, item, additionalData = {}, priority = 3) => {
        // Track performance
        const startTime = performance.now();
        
        // Add to running tasks set with priority metadata
        runningTasks.current.add(item.uuid);
        setActiveTaskCount(runningTasks.current.size);
        
        // Store priority with the task for potential future queue processing
        item.taskPriority = priority;
        
        // Initialize cancellation flag
        cancelTasks.current[item.uuid] = false;
        
        // Task function mapping for better maintainability
        const taskMap = {
            [TASK_TYPES.URL]: loadPageData,
            [TASK_TYPES.UPLOAD]: processUploadedImage,
            [TASK_TYPES.REGENERATE]: handleRegenerateTask,
            [TASK_TYPES.GENERATE_ONE]: (item) => handleOneGenerateTask(item, additionalData.imageIndex),
            [TASK_TYPES.GENERATE_MORE]: handleGenerateMoreTask
        };
        
        // Get the task handler function
        const taskHandler = taskMap[taskType];
        
        // Validate task type
        if (!taskHandler) {
            console.error(`Unknown task type: ${taskType}`);
            updateItem(item.uuid, {
                loading: false,
                error: true,
                errorMessage: `Unknown task type: ${taskType}`
            });
            
            runningTasks.current.delete(item.uuid);
            setActiveTaskCount(runningTasks.current.size);
            return;
        }
        
        try {
            // Check if the task has been cancelled before starting
            if (cancelTasks.current[item.uuid]) {
                console.log(`Task ${item.uuid} was cancelled before starting`);
                return;
            }
            
            // Execute the task handler
            await taskHandler(item);
            
            // Log completion time for performance monitoring
            const processingTime = performance.now() - startTime;
            console.log(`Task ${taskType} for ${item.uuid} completed in ${processingTime.toFixed(2)}ms`);
        } catch (error) {
            // Enhanced error logging
            console.error(`Error processing ${taskType} task for ${item.uuid}:`, error);
            
            // Update item with detailed error state
            updateItem(item.uuid, {
                loading: false,
                error: true,
                errorMessage: error.message || 'An error occurred while processing',
                errorDetails: {
                    taskType,
                    timestamp: new Date().toISOString(),
                    stack: error.stack
                }
            });
        } finally {
            // Only clean up if the task wasn't already cancelled during execution
            if (!cancelTasks.current[item.uuid]) {
                // Clear the cancellation flag
                delete cancelTasks.current[item.uuid];
                
                // Remove from running tasks
                runningTasks.current.delete(item.uuid);
                setActiveTaskCount(runningTasks.current.size);
            }
        }
    };

    /**
     * Handle submitting of new item
     * @param {*} newItem
     */
    const handleSubmit = (newItem) => {
        // If it's an uploaded image, handle it directly
        if (newItem.uploadedImage) {
            handleUploadedImage();
            return;
        }
        
        // If URL is provided, clear any uploaded image data in sessionStorage
        if (newItem.url) {
            sessionStorage.removeItem('uploadedImage');
        }
        
        setOverview((previousOverview) => {
            const uuid = uuidv4();
            
            newItem.uuid = uuid;
            if (!newItem.title) {
                newItem.title = 'Product ' + (previousOverview.length + 1);
            }

            newItem.loading = true;
            newItem.loadingProgress = 0;
            newItem.activeImage = 0;
            
            // Initialize empty image slots (4 is the default number of images)
            newItem.images = Array(4).fill(null);
            
            // Initialize loading states for all image slots
            newItem.loadingImages = {};
            for (let i = 0; i < 4; i++) {
                newItem.loadingImages[i] = true;
            }
            
            // Initialize empty generatedVideos array
            newItem.generatedVideos = [];
            
            newItem = { ...newItem, ...sidebarSettings };

            let newOVerviewList = [newItem, ...previousOverview];
            localStorage.setItem('overview', JSON.stringify(newOVerviewList));

            // Start URL processing immediately - tasks now run concurrently
            startTask(TASK_TYPES.URL, newItem);
            
            // Check if user data has been submitted
            if (!localStorage.getItem('userCreated')) {
                setShowUserModal(true);
            }

            return newOVerviewList;
        });
    };

    /**
     * Handle user data submission
     * @param {*} userData
     */
    const handleUserSubmit = async (userData) => {
        try {
            setIsSubmittingUser(true);
            await createUser(userData);
            localStorage.setItem('userCreated', 'true');
            setShowUserModal(false);
        } catch (error) {
            // Optionally show an error message to the user
        } finally {
            setIsSubmittingUser(false);
        }
    };

    const updateActiveImage = (uuid, newActiveImage) => {
        setOverview((prev) => {
            let newOverview = prev.map((item) => {
                if (item.uuid === uuid) {
                    return { ...item, activeImage: newActiveImage };
                }
                return item;
            });
            localStorage.setItem('overview', JSON.stringify(newOverview));
            return newOverview;
        });
    };

    /**
     * Handle remove of item
     * @param {*} uuid
     */
    const handleRemove = (uuid) => {
        // Cancel any ongoing task for this item
        cancelImageGeneration(uuid);
        
        setOverview((previousOverview) => {
            let newOverview = previousOverview.filter((item) => item.uuid !== uuid);
            localStorage.setItem('overview', JSON.stringify(newOverview));
            return newOverview;
        });
    };

    /**
     * Handle finetune
     * @param {*} item
     */
    const toggleFineTuneSidebar = (item) => {
        setIsFineTuneVisible((prevState) => !prevState);
        setCurrentFineTuneItem(item || null);
    };

    const handleDetails = (item) => {
        setShowDetails(item);
    };

    const handleInfo = () => {
        setShowInfo(true);
    };

    /**
     * Handle one generate - Entry point
     * @param {*} item
     * @param {*} imageIndex
     */
    const handleOneGenerate = (item, imageIndex) => {
        // Update loading state for this specific image
        updateItem(item.uuid, (prevItem) => {
            const newLoadingImages = { ...prevItem.loadingImages };
            newLoadingImages[imageIndex] = true;
            
            // Create a copy of the images array
            const updatedImages = [...(prevItem.images || [])];
            // Set the regenerating image to null to show loading state
            updatedImages[imageIndex] = null;
            
            return {
                ...prevItem,
                loading: true,
                loadingImages: newLoadingImages,
                images: updatedImages
            };
        });
        
        // Start the task immediately with high priority
        startTask(TASK_TYPES.GENERATE_ONE, item, { imageIndex }, 2);
    };
    
    /**
     * Task handler for single image generation
     */
    const handleOneGenerateTask = async (item, imageIndex) => {
        if (item.imagePrompts && item.imagePrompts.length > 0) {
            // Clone the existing images array to preserve other images
            const currentImages = [...(item.images || [])];
            
            // Get the prompt for this image - use modulo to cycle through prompts if needed
            const promptIndex = imageIndex % item.imagePrompts.length;
            const prompt = item.imagePrompts[promptIndex];
            
            // Create a new array with just the single prompt we need
            const prompts = Array(currentImages.length).fill(null);
            prompts[imageIndex] = prompt;
            
            // Generate the single image
            await generateImage(
                {
                    ...item,
                    imagePrompts: prompts,
                    appendImages: false
                },
                imageIndex + 1,  // Pass the correct image number (1-based index)
                0,  // Reset total time for single generation
                true,  // Stop after one
                true,  // Is regenerate
                currentImages
            );
        } else {
            // No prompts available, generate prompts first
            await generatePrompts(item, 1);
        }
    };

    /**
     * Regenerate item - Entry point
     * @param {*} updatedItem
     */
    const handleRegenerate = (updatedItem) => {
        updateItem(updatedItem.uuid, { loading: true, loadingProgress: 1 });
        
        // Start the task immediately with high priority
        startTask(TASK_TYPES.REGENERATE, updatedItem, {}, 2);
    };
    
    /**
     * Task handler for regeneration
     */
    const handleRegenerateTask = async (updatedItem) => {
        try {
            // Get the selected image
            const selectedImageIndex = updatedItem.activeImage || 0;
            const selectedImage = updatedItem.pageData?.productImages[selectedImageIndex]?.src;
            
            // Always update the productImage to match the selected image
            updateItem(updatedItem.uuid, { productImage: selectedImage });

            // Regenerate prompts and images with the selected image
            const newPromptsResult = await generateCopy({
                title: updatedItem.pageData.title,
                description: updatedItem.pageData.description,
                imageUrl: selectedImage
            });

            if (newPromptsResult?.imagePrompts) {
                // First preprocess the selected image
                const preprocessedImages = await preprocessImages(selectedImage);
                
                // Update item with new prompts and preprocessed image
                updateItem(updatedItem.uuid, {
                    imagePrompts: newPromptsResult.imagePrompts,
                    loadingProgress: 2,
                    productImage: selectedImage,
                    preprocessedImages: preprocessedImages
                });

                // Store existing images before generating new ones
                const existingImages = updatedItem.images || [];

                // Generate new batch of images
                const newImages = await generateImage({ 
                    ...updatedItem, 
                    imagePrompts: newPromptsResult.imagePrompts,
                    images: existingImages,
                    productImage: selectedImage,
                    preprocessedImages: preprocessedImages,
                    appendImages: true
                }, 1);

                // Update the item with combined images
                updateItem(updatedItem.uuid, {
                    images: newImages,
                    loading: false,
                    loadingProgress: 6
                });
            } else {
                updateItem(updatedItem.uuid, {
                    loading: false,
                    error: true,
                    errorMessage: 'Failed to regenerate prompts'
                });
            }
        } catch (error) {
            updateItem(updatedItem.uuid, {
                loading: false,
                error: true,
                errorMessage: error.message || 'An error occurred'
            });
        }
    };

    /**
     * Process URL task
     * @param {*} newItem
     */
    const loadPageData = async (newItem) => {
        try {
            // Update loading state but preserve loadingImages
            updateItem(newItem.uuid, (prevItem) => ({
                ...prevItem,
                loading: true,
                loadingProgress: 1,
                // Ensure loadingImages is preserved
                loadingImages: prevItem.loadingImages || {}
            }));

            // Parse the page data
            const pageData = await parsePage(newItem.url);
            
            // Ensure productImages is always an array
            if (!pageData.productImages || !Array.isArray(pageData.productImages) || pageData.productImages.length === 0) {
                pageData.productImages = [{ src: newItem.productImage || '' }];
            }
            
            // Check if task was cancelled
            if (cancelTasks.current[newItem.uuid]) {
                return;
            }
            
            // Update with the parsed data, preserving loadingImages
            updateItem(newItem.uuid, (prevItem) => ({
                ...prevItem,
                pageData: pageData,
                productImage: pageData.productImages[0]?.src || '',
                loadingProgress: 1,
                // Ensure loadingImages is preserved
                loadingImages: prevItem.loadingImages || {}
            }));
            
            // Continue with prompt generation
            await generatePrompts({
                ...newItem,
                pageData: pageData,
                productImage: pageData.productImages[0]?.src || ''
            });
            
        } catch (error) {
            updateItem(newItem.uuid, {
                loading: false,
                error: true,
                errorMessage: error.message || 'Failed to parse page data'
            });
        }
    };

    /**
     * Generate prompts with AI
     * @param {*} newData
     * @param {*} nrOfImages - number of images to generate
     */
    const generatePrompts = async (newData, nrOfImages = 4) => {
        // Check if task was cancelled
        if (cancelTasks.current[newData.uuid]) {
            return;
        }
        
        if (newData.imagePrompts && newData.imagePrompts.length > 0) {
            // Update only the specific image that was clicked
            updateItem(newData.uuid, {
                loadingProgress: 2
            });

            // Regenerate the specific image (one prompt)
            await generateImage(newData, 1, nrOfImages);
        } else {
            try {
                let result = await generateCopy({
                    title: newData.pageData?.title,
                    description: newData.pageData?.description,
                    imageUrl: newData.productImage
                });

                // Check if task was cancelled
                if (cancelTasks.current[newData.uuid]) {
                    return;
                }

                if (result?.imagePrompts) {
                    updateItem(newData.uuid, {
                        imagePrompts: result.imagePrompts,
                        loadingProgress: 2
                    });
                    
                    // Preprocess the image before generating
                    const preprocessedImages = await preprocessImages(newData.productImage);
                    
                    // Check if task was cancelled
                    if (cancelTasks.current[newData.uuid]) {
                        return;
                    }
                    
                    updateItem(newData.uuid, {
                        preprocessedImages: preprocessedImages
                    });
                    
                    await generateImage({ 
                        ...newData, 
                        imagePrompts: result.imagePrompts,
                        preprocessedImages: preprocessedImages
                    }, 1, nrOfImages);
                } else {
                    throw new Error('Failed to generate prompts');
                }
            } catch (error) {
                updateItem(newData.uuid, {
                    loading: false,
                    error: true,
                    errorMessage: error.message || 'Failed to generate prompts'
                });
            }
        }
    };

    /**
     * Generate image
     * @param {*} inputData
     * @param {*} nr - Current image number being generated
     * @param {*} totalTime - Accumulated time
     * @param {*} stopAfterOne - Flag to stop after one image
     * @param {*} isRegenerate - Whether this is a regeneration
     * @param {*} currentImages - Current images array
     */
    const generateImage = async (
        inputData,
        nr,
        totalImages = 0,
        stopAfterOne = false,
        isRegenerate = false,
        currentImages = null
    ) => {
        const startTime = Date.now();
        const imageIndex = nr - 1; // Convert to 0-based index
        const imagePrompt = inputData.imagePrompts[imageIndex]?.prompt;

        // Initialize currentImages properly if not provided
        if (currentImages === null) {
            currentImages = inputData.images || [];
        }

        // Check if we have a valid prompt
        if (!imagePrompt) {
            return currentImages;
        }
        
        // Check if task was cancelled
        if (cancelTasks.current[inputData.uuid]) {
            return currentImages;
        }

        try {
            // Calculate the actual index based on whether we're appending
            const actualIndex = inputData.appendImages 
                ? (currentImages.length || 0) 
                : imageIndex;
            
            // Update loading state ONLY for the specific image
            updateItem(inputData.uuid, (prevItem) => {
                // Create a clean loadingImages object
                const newLoadingImages = { ...prevItem.loadingImages };
                
                // Set only the current image as loading and remove the existing image
                newLoadingImages[actualIndex] = true;
                
                // Create a copy of the images array
                const updatedImages = [...(prevItem.images || [])];
                // Remove the existing image while it's regenerating
                if (isRegenerate) {
                    updatedImages[actualIndex] = null;
                }
                
                return {
                    ...prevItem,
                    loading: true,
                    loadingImages: newLoadingImages,
                    images: updatedImages
                };
            });

            // Ensure we have preprocessed image
            const imageSource = inputData.preprocessedImages?.[0] || 
                (await preprocessImages(inputData.productImage))[0];
                
            // Check again if task was cancelled before proceeding
            if (cancelTasks.current[inputData.uuid]) {
                return currentImages;
            }

            const imageOutput = await createImages({
                prompt: imagePrompt,
                url: imageSource,
                imageIndex: actualIndex
            });

            // Check again if task was cancelled
            if (cancelTasks.current[inputData.uuid]) {
                return currentImages;
            }

            if (!imageOutput?.image_urls?.[0]) {
                throw new Error('No image URL in generation output');
            }

            // Update images array based on whether we're appending or replacing
            let updatedImages;
            if (inputData.appendImages && !isRegenerate) {
                updatedImages = [...currentImages, imageOutput.image_urls[0]];
            } else {
                // Create a copy of the current images array
                updatedImages = [...currentImages];
                // Update the specific index
                updatedImages[actualIndex] = imageOutput.image_urls[0];
            }

            const timePassed = Date.now() - startTime;
            const newTotalTime = totalImages + timePassed;

            // Calculate progress based on how many images we've generated
            const totalImagesToGenerate = inputData.imagePrompts?.length || updatedImages.length;
            const completedImages = updatedImages.filter(img => img).length;
            const progress = Math.min(100, Math.round((completedImages / totalImagesToGenerate) * 100));

            // Update item with new image and progress
            updateItem(inputData.uuid, (prevItem) => {
                const newLoadingImages = { ...prevItem.loadingImages };
                newLoadingImages[actualIndex] = false;
                
                return {
                    ...prevItem,
                    loadingProgress: progress,
                    images: updatedImages,
                    loading: !stopAfterOne && nr < totalImagesToGenerate,
                    totalTime: newTotalTime,
                    loadingImages: newLoadingImages
                };
            });
            
            // Continue generating if needed and not cancelled
            if (!stopAfterOne && nr < totalImagesToGenerate && !cancelTasks.current[inputData.uuid]) {
                return await generateImage(
                    inputData,
                    nr + 1,
                    newTotalTime,
                    false,
                    isRegenerate,
                    updatedImages
                );
            }

            return updatedImages;
        } catch (error) {
            // Use the same actualIndex calculation here for consistency
            const actualIndex = inputData.appendImages 
                ? (currentImages.length) 
                : imageIndex;
            
            updateItem(inputData.uuid, (prevItem) => {
                const newLoadingImages = { ...prevItem.loadingImages };
                newLoadingImages[actualIndex] = false;
                
                return {
                    ...prevItem,
                    loading: false,
                    error: true,
                    errorMessage: error.message || 'Failed to generate image',
                    loadingImages: newLoadingImages
                };
            });
            return currentImages;
        }
    };

    /**
     * Set data to update item
     * @param {*} uuid
     * @param {*} updater
     */
    const updateItem = (uuid, updater) => {
        setOverview((prevItems) => {
            const newItems = prevItems.map((item) => {
                if (item.uuid === uuid) {
                    // Handle both function and object updates
                    const updatedItem = typeof updater === 'function' 
                        ? updater(item)
                        : { ...item, ...updater };
                    
                    return updatedItem;
                }
                return item;
            });

            // Persist to localStorage after the update
            localStorage.setItem('overview', JSON.stringify(newItems));
            return newItems;
        });
    };

    /**
     * Delete single image
     */
    const handleDeleteImage = (uuid, imageKey) => {
        const imageIndex = Number(imageKey.split('_')[1]);
        setOverview((prevItems) => {
            const updatedOverview = prevItems.map((item) => {
                if (item.uuid === uuid) {
                    const updatedImages = item.images?.filter((_, index) => index !== imageIndex);
                    return { ...item, images: updatedImages };
                }
                return item;
            });
            localStorage.setItem('overview', JSON.stringify(updatedOverview));

            return updatedOverview;
        });
    };

    const formatTime = (timeInMilliseconds) => {
        if (!timeInMilliseconds) return;

        const totalSeconds = Math.floor(timeInMilliseconds / 1000);
        const minutes = Math.floor(totalSeconds / 60); // Get minutes
        const seconds = totalSeconds % 60; // Get remaining seconds

        return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
    };

    /**
     * Handle generate more - Entry point
     */
    const handleGenerateMore = (item) => {
        // Start the task immediately with medium priority
        startTask(TASK_TYPES.GENERATE_MORE, item, {}, 3);
    };
    
    /**
     * Task handler for generate more
     */
    const handleGenerateMoreTask = async (item) => {
        // Calculate the starting index for new images
        const startIndex = item.images?.length || 0;
        
        // Clone the item and set appendImages flag
        const updatedItem = {
            ...item,
            appendImages: true
        };
        
        // Update loading state for ALL new images being generated
        updateItem(updatedItem.uuid, (prevItem) => {
            // Create new loadingImages object preserving existing states
            const newLoadingImages = { ...prevItem.loadingImages };
            
            // Set loading state for all 4 new images
            for (let i = startIndex; i < startIndex + 4; i++) {
                newLoadingImages[i] = true;
            }

            // Create a new images array that includes placeholders for new images
            const currentImages = [...(prevItem.images || [])];
            // Add null placeholders for new images
            for (let i = startIndex; i < startIndex + 4; i++) {
                currentImages[i] = null;
            }
            
            return {
                ...prevItem,
                appendImages: true,
                loading: true,
                loadingImages: newLoadingImages,
                images: currentImages
            };
        });
        
        // Then proceed with generation
        await generatePrompts(updatedItem);
    };

    // Add this function to ToolController
    const updateVideos = (uuid, videos) => {
        updateItem(uuid, (prevItem) => ({
            ...prevItem,
            generatedVideos: videos
        }));
    };

    /**
     * Handle uploaded image - Entry point
     */
    const handleUploadedImage = () => {
        // Get image data from sessionStorage
        const uploadedImageData = sessionStorage.getItem('uploadedImage');
        
        if (!uploadedImageData) {
            alert('No image data found. To use an image, please select one from your device. Alternatively, you can switch to URL mode to process a product URL instead.');
            return;
        }
        
        // Validate the image data format
        if (typeof uploadedImageData !== 'string' || !uploadedImageData.startsWith('data:image')) {
            alert('Invalid image data format. Please try uploading again.');
            sessionStorage.removeItem('uploadedImage');
            return;
        }
        
        // Clear the sessionStorage immediately to prevent duplicate processing
        // We'll save a local copy for this session only
        const imageDataForProcessing = uploadedImageData;
        sessionStorage.removeItem('uploadedImage');
        
        // Create a new item
        const uuid = uuidv4();
        const newItem = {
            uuid: uuid,
            title: 'Uploaded Image',
            loading: true,
            loadingProgress: 0,
            activeImage: 0,
            uploadedImageData: imageDataForProcessing, // Store locally for this task only
            // Initialize empty image slots
            images: Array(4).fill(null),
            // Initialize loading states for all image slots
            loadingImages: Object.fromEntries([0,1,2,3].map(i => [i, true])),
            // Initialize empty generatedVideos array
            generatedVideos: []
        };
        
        // Add the new item to the overview WITHOUT causing a page refresh
        setOverview((previousOverview) => {
            // Preserve any existing items in the overview
            const newOverview = [newItem, ...previousOverview];
            localStorage.setItem('overview', JSON.stringify(newOverview));
            return newOverview;
        });
        
        // Clean up URL parameters to prevent unexpected refreshes
        if (window.location.search) {
            const newUrl = window.location.pathname;
            window.history.replaceState({}, document.title, newUrl);
        }
        
        // Start the task immediately with highest priority
        startTask(TASK_TYPES.UPLOAD, newItem, {}, 1);
    };
    
    /**
     * Task handler for uploaded image processing
     */
    const processUploadedImage = async (item) => {
        try {
            // Use the locally stored image data rather than getting from sessionStorage
            const uploadedImageData = item.uploadedImageData;
            
            if (!uploadedImageData) {
                throw new Error('Image data not found');
            }
            
            // Upload the image to get a URL
            const uploadedImageUrl = await uploadImage(uploadedImageData);
            
            // Check if task was cancelled
            if (cancelTasks.current[item.uuid]) {
                return;
            }
            
            // Update the item with the product image URL
            updateItem(item.uuid, {
                productImage: uploadedImageUrl,
                // Remove the large base64 data we no longer need
                uploadedImageData: null,
                pageData: {
                    title: 'Uploaded Product',
                    description: 'Custom uploaded product image',
                    productImages: [{ src: uploadedImageUrl }]
                }
            });
            
            // First preprocess the uploaded image
            const preprocessedImages = await preprocessImages(uploadedImageUrl, true);
            
            // Check if task was cancelled
            if (cancelTasks.current[item.uuid]) {
                return;
            }
            
            // Update the item with preprocessed images
            updateItem(item.uuid, {
                preprocessedImages: preprocessedImages,
                loadingProgress: 1
            });
            
            // Generate copy based on the image
            const copyResult = await generateCopy({
                imageUrl: uploadedImageUrl
            });
            
            // Check if task was cancelled
            if (cancelTasks.current[item.uuid]) {
                return;
            }
            
            if (copyResult?.imagePrompts) {
                // Update the item with the prompts
                updateItem(item.uuid, {
                    imagePrompts: copyResult.imagePrompts,
                    loadingProgress: 2
                });
                
                // Generate images
                await generateImage({
                    ...item,
                    imagePrompts: copyResult.imagePrompts,
                    preprocessedImages: preprocessedImages,
                    productImage: uploadedImageUrl
                }, 1);
            } else {
                throw new Error('Failed to generate prompts');
            }
        } catch (error) {
            updateItem(item.uuid, {
                loading: false,
                error: true,
                errorMessage: error.message || 'Failed to process uploaded image',
                // Remove the large base64 data in case of error too
                uploadedImageData: null
            });
        }
    };

    /**
     * Upon loading component, check if there is an import
     */
    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const importData = urlParams.get('import');
        const url = urlParams.get('url');
        const isUploadedImage = urlParams.get('uploadedImage') === 'true';

        // Process items in order to prevent unexpected behavior
        let hasProcessedItems = false;

        if (importData) {
            let importedData = JSON.parse(atob(importData));
            handleImport(importedData);
            hasProcessedItems = true;
        }
        
        // Process URL if present and not already processing something
        if (url && !loading.current && !overview.some(item => item.url === url)) {
            loading.current = true;
            handleSubmit({ url: url });
            hasProcessedItems = true;
        }

        // Handle uploaded image if present
        if (isUploadedImage && !hasProcessedItems) {
            // Only directly handle uploaded image if no other items were processed
            handleUploadedImage();
            hasProcessedItems = true;
        }
        
        // Clean up URL parameters after processing to prevent reprocessing
        if (url || importData || isUploadedImage) {
            // Use history API to remove the parameters without page reload
            const newUrl = window.location.pathname;
            window.history.replaceState({}, document.title, newUrl);
        }
    }, []);

    /**
     * Import data upon load
     * @param {*} importedData
     */
    const handleImport = (importedData) => {
        setOverview((prev) => {
            let newObject = [importedData, ...prev];
            localStorage.setItem('overview', JSON.stringify(newObject));
            return newObject;
        });
    };

    return (
        <div className={'tool ' + (isFineTuneVisible || showInfo || showSidebarSetting || showDetails ? 'sidebar' : '')}>
            {isFineTuneVisible && currentFineTuneItem && (
                <SidebarFineTune
                    item={currentFineTuneItem}
                    onSubmit={handleRegenerate}
                    activeImage={activeImage[currentFineTuneItem.uuid] || 0}
                    setActiveImage={(newActiveImage) => {
                        setActiveImage((prev) => ({
                            ...prev,
                            [currentFineTuneItem.uuid]: newActiveImage
                        }));
                        updateItem(currentFineTuneItem.uuid, { activeImage: newActiveImage });
                    }}
                    onClose={() => {
                        setIsFineTuneVisible(false);
                        setCurrentFineTuneItem(null);
                    }}
                />
            )}
            {showInfo && <SidebarInfo onClose={() => setShowInfo(false)} />}

            {showSidebarSetting && (
                <SidebarSetting
                    sidebarSettings={sidebarSettings}
                    url={url}
                    onClose={() => setShowSidebarSetting(false)}
                    onChange={(updatedSettings) => {
                        setSidebarSettings(updatedSettings);
                    }}
                    onSubmit={handleSettingSidebarSave}
                    onGenerate={handleSubmit}
                />
            )}

            {showDetails && <SidebarDetails item={showDetails} onClose={() => setShowDetails(false)} />}

            {/* User prompt modal */}
            <UserPromptModal
                open={showUserModal}
                onClose={() => setShowUserModal(false)}
                onSubmit={handleUserSubmit}
                isSubmitting={isSubmittingUser}
            />

            <ToolTopBar 
                onSubmit={handleSubmit} 
                onShowSidebarSetting={handleSidebarSettingOpen} 
                onChangeUrl={(url) => setUrl(url)} 
                url={url}
                onShowInfo={handleInfo}
                activeTaskCount={activeTaskCount}
            />

            {(!overview || overview.length === 0) && (
                <div className="tool__no-content">
                    <EmptyState />
                </div>
            )}

            <div
                className={'tool__list'}
                style={{
                    paddingTop: isAlertVisible ? '9em' : '6em'
                }}>
                {activeTaskCount > 0 && (
                    <div className="tool__status-bar" style={{
                        position: 'fixed',
                        bottom: '20px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 100,
                        background: '#f0f8ff',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 'auto',
                        maxWidth: '90%'
                    }}>
                        <span>
                            {activeTaskCount === 1 
                                ? '1 product is currently being processed' 
                                : `${activeTaskCount} products are being processed concurrently`}
                        </span>
                    </div>
                )}
                {overview.map((item) => (
                    <ListItem
                        item={item}
                        key={item.uuid}
                        onRemove={() => handleRemove(item.uuid)}
                        onDeleteImage={handleDeleteImage}
                        onFinetuneSetting={() => toggleFineTuneSidebar(item)}
                        onOneRegenerate={handleOneGenerate}
                        activeImage={item.activeImage}
                        setActiveImage={(newActiveImage) => {
                            updateActiveImage(item.uuid, newActiveImage);
                            setActiveImage((prev) => ({
                                ...prev,
                                [item.uuid]: newActiveImage
                            }));
                        }}
                        onGenerateMore={handleGenerateMore}
                        cancelButton={() => cancelImageGeneration(item.uuid)}
                        handleDetails={() => handleDetails(item)}
                        totalTime={formatTime(item.totalTime)}
                        updateVideos={updateVideos}
                    />
                ))}
            </div>
        </div>
    );
};

export default ToolController;