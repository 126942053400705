import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import privacyPolicy from './assets/privacy_policy_july2023.pdf';
import Demo from './assets/CapeAIStudioFeature.mp4';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    
    return (
        <footer className="footer">
            <div className="footer-content">
                <h2>Ready to experience?</h2>
                <p>Start generating your assets in seconds</p>
                <div className="footer-content-buttons buttons">
                    <Link to="/tool" className="btn btn-primary">
                        Try it out
                    </Link>
                    <a href={Demo} target="_blank" rel="noopener noreferrer" className="btn btn-secondary">
                        View demo
                    </a>
                </div>
            </div>

            <div className="footer-details">
                <span>
                    Prodshot © {currentYear} – Part of{' '}
                    <a href="https://www.bycape.io/" target="_blank" rel="noopener noreferrer">
                        Cape
                    </a>
                </span>
                <a href={privacyPolicy} target="_blank" rel="noopener noreferrer">
                    Terms and Conditions
                </a>
            </div>
        </footer>
    );
};

export default Footer;
