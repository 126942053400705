import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'X-API-Token': process.env.REACT_APP_API_TOKEN
    }
});

export const parsePage = async (url) => {
    const response = await api.post('/parser/parse', { url });
    return response.data;
};

export const generateCopy = async (data) => {
    // Ensure title and description are provided for image uploads
    if (data.imageUrl && !data.title) {
        data.title = 'Uploaded Product';
    }
    
    if (data.imageUrl && !data.description) {
        data.description = 'Custom uploaded product image';
    }
    
    // If the imageUrl is a base64 string, we need to upload it first
    if (data.imageUrl && typeof data.imageUrl === 'string' && data.imageUrl.startsWith('data:image')) {
        // Generate a unique filename with timestamp and random string
        const timestamp = Date.now();
        const randomStr = Math.random().toString(36).substring(2, 10);
        const filename = `uploaded-image-${timestamp}-${randomStr}.jpg`;
        
        // The base64 string is already in the correct format for the API
            const uploadResponse = await api.post('/storage/upload', {
                data: data.imageUrl,
                filename: filename,
                bucket: process.env.REACT_APP_STORAGE_BUCKET
            });
            
            if (uploadResponse.data?.url) {
                data.imageUrl = uploadResponse.data.url;
            } else {
                throw new Error('Failed to upload image');
            }
    }
    
    const response = await api.post('/generate-copy/generate', data);
    return response.data;
};

export const preprocessImages = async (urls, skipUpload = false) => {
    // Ensure we're only sending an array, even for a single URL
    const urlArray = Array.isArray(urls) ? urls : [urls];
    
    // Check if any URL is a base64 string
    const hasBase64 = !skipUpload && urlArray.some(url => typeof url === 'string' && url.startsWith('data:image'));
    
    if (hasBase64) {
        // For base64 images, we need to upload them first
        const processedUrls = [];
        
        for (let i = 0; i < urlArray.length; i++) {
            if (typeof urlArray[i] === 'string' && urlArray[i].startsWith('data:image')) {
                // Generate a unique filename with timestamp and random string
                const timestamp = Date.now();
                const randomStr = Math.random().toString(36).substring(2, 10);
                const filename = `uploaded-image-${i}-${timestamp}-${randomStr}.jpg`;
                
                // Upload the base64 image directly using the format from the cURL example
                    const uploadResponse = await api.post('/storage/upload', {
                        data: urlArray[i],
                        filename: filename,
                        bucket: process.env.REACT_APP_STORAGE_BUCKET
                    });
                    
                    if (!uploadResponse.data?.url) {
                        throw new Error('Failed to upload image');
                    }
                    
                    const imageUrl = uploadResponse.data.url;
                    
                    // Now preprocess the uploaded image
                    const response = await api.post('/preprocess-image/process', { 
                        urls: [imageUrl] 
                    });
                    
                    if (response.data?.processedUrls?.length) {
                        processedUrls.push(...response.data.processedUrls);
                    }
            } else {
                // Regular URL processing
                const response = await api.post('/preprocess-image/process', { 
                    urls: [urlArray[i]] 
                });
                
                if (response.data?.processedUrls?.length) {
                    processedUrls.push(...response.data.processedUrls);
                }
            }
        }
        
        if (!processedUrls.length) {
            throw new Error('No processed URLs returned');
        }
        
        return processedUrls;
    } else {
        // Regular URL processing for all URLs
        const response = await api.post('/preprocess-image/process', { urls: urlArray });
        
        if (!response.data?.processedUrls?.length) {
            throw new Error('No processed URLs returned');
        }
        
        return response.data.processedUrls;
    }
};

// Helper function to upload an image once and return the URL
export const uploadImage = async (base64Image, index = 0) => {
    if (!base64Image || typeof base64Image !== 'string' || !base64Image.startsWith('data:image')) {
        throw new Error('Invalid image data');
    }
    
    // Generate a unique filename with timestamp and random string
    const timestamp = Date.now();
    const randomStr = Math.random().toString(36).substring(2, 10);
    const filename = `uploaded-image-${index}-${timestamp}-${randomStr}.jpg`;
    
    try {
        const uploadResponse = await api.post('/storage/upload', {
            data: base64Image,
            filename: filename,
            bucket: process.env.REACT_APP_STORAGE_BUCKET
        });
        
        if (!uploadResponse.data?.url) {
            throw new Error('Failed to upload image');
        }
        
        return uploadResponse.data.url;
    } catch (error) {
        throw new Error(`Failed to upload image: ${error.message || 'Unknown error'}`);
    }
};

export const createImages = async (data) => {
    if (!data.prompt || typeof data.prompt !== 'string') {
        throw new Error('Invalid prompt: Must be a non-empty string');
    }
    if (!data.url || typeof data.url !== 'string' || !data.url.startsWith('http')) {
        throw new Error('Invalid imageUrl: Must be a valid URL string');
    }

    const response = await api.post('/generate-image/generate', {
        prompt: data.prompt,
        imageUrl: data.url,
        num_inference_steps: 20,
        guidance_scale: 7.5,
        seed: Math.floor(Math.random() * 2147483647)
    });

    const result = response.data;
    
    // Handle both new and legacy response formats
    const generatedUrl = result.images?.[0]?.url || result.imageUrls?.[0];
    
    if (!generatedUrl) {
        throw new Error('No image URL in response');
    }

    // Return object with required image_urls format and any available metadata
    return {
        image_urls: [generatedUrl],
        imageIndex: data.imageIndex,
        metadata: {
            prompt: result.prompt,
            ...(result.images?.[0]?.content_type && { contentType: result.images[0].content_type }),
            ...(result.timings && { timings: result.timings }),
            ...(result.seed && { seed: result.seed }),
            ...(result.has_nsfw_concepts && { hasNsfwConcepts: result.has_nsfw_concepts }),
            ...(result.requestId && { requestId: result.requestId })
        }
    };
};

// video generation
export const generateVideo = async (prompt, imageUrl) => {
    try {
        if (!prompt || typeof prompt !== 'string') {
            throw new Error('Invalid prompt: Must be a non-empty string');
        }
        if (!imageUrl || typeof imageUrl !== 'string' || !imageUrl.startsWith('http')) {
            throw new Error('Invalid imageUrl: Must be a valid URL string');
        }

        const input = {
            prompt: prompt.trim(),
            imageUrl: imageUrl,
            duration: "5",
            aspect_ratio: "1:1"
        };

        const response = await api.post('/generate-video/generate', input);

        const videoUrl = response.data?.video?.url || response.data?.videoUrl;
        
        if (!videoUrl) {
            throw new Error('No video URL in response');
        }

        return videoUrl;
    } catch (error) {
        throw new Error(error.message || 'Failed to generate video');
    }
};

export const createUser = async (userData) => {
    const response = await api.post('/user/create', userData);
    return response.data;
};