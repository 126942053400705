import React from "react";
import "./style.scss";
import "../../../../animations.scss";

const Showcase = () => {
  const images = require.context("./assets", false, /\.(jpg|jpeg|png|gif)$/);
  const imageArray = images.keys().map((image) => images(image));
  
  // Create three sets of images to ensure smooth infinite loop
  const duplicatedImages = [...imageArray, ...imageArray, ...imageArray];

  return (
    <div className="showcase">
      <div className="showcase-container">
        {duplicatedImages.map((image, index) => (
          <div className="showcase-image" key={index}>
            <img src={image} alt={`Product Shot ${(index % imageArray.length) + 1}`} loading="lazy" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Showcase;
