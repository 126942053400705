import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';

const InputField = () => {
    const [value, setValue] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [activeTab, setActiveTab] = useState('url'); // 'url' or 'image'
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const fileInputRef = useRef(null);

    const navigate = useNavigate();

    // URL validation function
    const isValidUrl = (url) => {
        try {
            // Check if it's a valid URL format
            const urlObj = new URL(url);
            // Check if it has http or https protocol
            return urlObj.protocol === 'http:' || urlObj.protocol === 'https:';
        } catch (e) {
            return false;
        }
    };

    const handleLink = () => {
        if (activeTab === 'url') {
            // Validate URL before submitting
            if (!isValidUrl(value)) {
                setError('Please enter a valid URL (e.g., https://example.com)');
                return;
            }
            setError('');
        }

        if ((activeTab === 'url' && value) || (activeTab === 'image' && selectedFile)) {
            setIsSubmitting(true);
            
            if (activeTab === 'url' && value) {
                // Add a small delay to show the glow effect before navigating
                setTimeout(() => {
                    navigate(`/tool?url=${value}`);
                }, 800);
            } else if (activeTab === 'image' && selectedFile) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    sessionStorage.setItem('uploadedImage', reader.result);
                    // Add a small delay to show the glow effect before navigating
                    setTimeout(() => {
                        navigate('/tool?uploadedImage=true');
                    }, 800);
                };
                reader.readAsDataURL(selectedFile);
            }
        }
    };

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedFile(e.target.files[0]);
            setActiveTab('image');
            setError('');
        }
    };

    // Reset submitting state if component unmounts
    useEffect(() => {
        return () => {
            setIsSubmitting(false);
        };
    }, []);

    return (
        <div className="modern-input-container">
            <div className="input-group">
                <div className="option-buttons">
                    <button 
                        className={`option-btn ${activeTab === 'url' ? 'active' : ''}`}
                        onClick={() => {
                            setActiveTab('url');
                            setSelectedFile(null);
                            setError('');
                        }}
                    >
                        <svg className="link-icon" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M3.9,12c0-1.71,1.39-3.1,3.1-3.1h4V7H7c-2.76,0-5,2.24-5,5s2.24,5,5,5h4v-1.9H7C5.29,15.1,3.9,13.71,3.9,12z M8,13h8v-2H8V13z M17,7h-4v1.9h4c1.71,0,3.1,1.39,3.1,3.1s-1.39,3.1-3.1,3.1h-4V17h4c2.76,0,5-2.24,5-5S19.76,7,17,7z" />
                        </svg>
                        <span>URL</span>
                    </button>
                    
                    <button 
                        className={`option-btn ${activeTab === 'image' ? 'active' : ''}`}
                        onClick={() => {
                            setActiveTab('image');
                            fileInputRef.current.click();
                            setError('');
                        }}
                    >
                        <svg className="upload-icon" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M5,10h4v6h6v-6h4l-7-7L5,10z M5,18v2h14v-2H5z" />
                        </svg>
                        <span>Image</span>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept="image/*"
                            style={{ display: 'none' }}
                        />
                    </button>
                </div>
                
                <div className="input-wrapper">
                    <input
                        type="text"
                        value={activeTab === 'url' ? value : selectedFile ? selectedFile.name : ''}
                        placeholder="e.g., https://webshop.com/"
                        className={`input-field ${error ? 'error' : ''}`}
                        onChange={(e) => {
                            if (activeTab === 'url') {
                                setValue(e.target.value);
                                setError('');
                            }
                        }}
                        readOnly={activeTab === 'image'}
                    />
                    
                    <button 
                        className={`submit-button ${isSubmitting ? 'submitting' : ''} ${(activeTab === 'url' && value) || (activeTab === 'image' && selectedFile) ? 'active' : ''}`}
                        onClick={handleLink}
                        disabled={(activeTab === 'url' && !value) || (activeTab === 'image' && !selectedFile)}
                    >
                        <svg className="play-icon" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M8,5v14l11-7L8,5z" />
                        </svg>
                    </button>
                </div>
            </div>
            
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default InputField;
