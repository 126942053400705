import React from 'react';
import { Box, Button, IconButton, Slider, Typography, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import './style.scss';
import { SidebarSettingsData, FormatOptions } from 'types';

interface SidebarSettingProps {
    sidebarSettings: SidebarSettingsData;
    url: string;
    onClose: () => void;
    onChange: (updatedSettings: SidebarSettingsData) => void;
    onGenerate: (newItem: { url: string }) => void;
    onSubmit: (updatedSettings: SidebarSettingsData) => void;
}

const SidebarSetting: React.FC<SidebarSettingProps> = ({ sidebarSettings, url, onClose, onChange, onGenerate, onSubmit }) => {
    const handleFormatChange = (event: React.MouseEvent<HTMLElement>, newFormat: FormatOptions | null) => {
        if (newFormat !== null) {
            onChange({ ...sidebarSettings, format: newFormat });
        }
    };

    const handleYSliderChange = (value: number | number[]) => {
        if (Array.isArray(value)) return;
        onChange({ ...sidebarSettings, yAxis: value });
    };

    const handleXSliderChange = (value: number | number[]) => {
        if (Array.isArray(value)) return;
        onChange({ ...sidebarSettings, xAxis: value });
    };

    const handleScaleSliderChange = (value: number | number[]) => {
        if (Array.isArray(value)) return;
        onChange({ ...sidebarSettings, scale: value });
    };

    const handleButtonClick = () => {
        if (url) {
            onGenerate({ url });
        } else {
            onSubmit(sidebarSettings);
        }
        onClose();
    };

    return (
        <Box className="sidebar-setting" sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
            <div className="sidebar-setting__header">
                <Typography>Generation setting</Typography>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </div>
            
            {/* Content with overlay */}
            <div className="sidebar-setting__content-wrapper">
                <div className="sidebar-setting__box">
                    <Box className="sidebar-setting__box__title" display="flex" color="inherit">
                        <PhotoSizeSelectLargeIcon className="sidebar-setting__box__icon" fontSize="small" />
                        <Typography>Output settings</Typography>
                    </Box>
                    <div className="sidebar-setting__box__content">
                        <ToggleButtonGroup 
                            color="primary" 
                            value={sidebarSettings.format} 
                            exclusive 
                            onChange={handleFormatChange} 
                            fullWidth 
                            size="small" 
                            sx={{ mb: 2 }}
                            disabled
                        >
                            <ToggleButton sx={{ textTransform: 'none' }} value="square">
                                Square
                            </ToggleButton>
                            <ToggleButton sx={{ textTransform: 'none' }} value="portrait">
                                Portrait
                            </ToggleButton>
                            <ToggleButton sx={{ textTransform: 'none' }} value="landscape">
                                Landscape
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Typography fontSize="small" gutterBottom>
                            Position on X-axis
                        </Typography>
                        <Slider 
                            size="small" 
                            min={0} 
                            max={100} 
                            value={sidebarSettings.xAxis} 
                            valueLabelDisplay="auto" 
                            onChange={(_e, value: number | number[]) => handleXSliderChange(value)} 
                            disabled
                        />
                        <Typography fontSize="small" gutterBottom>
                            Position on Y-axis
                        </Typography>
                        <Slider 
                            size="small" 
                            min={0} 
                            max={100} 
                            value={sidebarSettings.yAxis} 
                            valueLabelDisplay="auto" 
                            onChange={(_e, value: number | number[]) => handleYSliderChange(value)} 
                            disabled
                        />
                        <Typography fontSize="small" gutterBottom>
                            Scale
                        </Typography>
                        <Slider 
                            size="small" 
                            min={0} 
                            max={100} 
                            value={sidebarSettings.scale} 
                            valueLabelDisplay="auto" 
                            onChange={(_e, value: number | number[]) => handleScaleSliderChange(value)} 
                            disabled
                        />
                    </div>
                </div>
                
                {/* Overlay message */}
                <div className="sidebar-setting__overlay">
                    <Paper elevation={3} className="sidebar-setting__overlay-message">
                        <Typography variant="h6" align="center">
                            Feature in Development
                        </Typography>
                        <Typography variant="body2" align="center">
                            These settings are not currently applicable.
                        </Typography>
                    </Paper>
                </div>
            </div>
            
            <div className="sidebar-setting__footer">
                <Button 
                    variant="contained" 
                    sx={{ boxShadow: 'none' }} 
                    onClick={handleButtonClick} 
                    fullWidth
                    disabled={!url} // Only enable if URL is provided (for Generate)
                >
                    {url ? 'Generate' : 'Save'}
                </Button>
            </div>
        </Box>
    );
};

export default SidebarSetting;
